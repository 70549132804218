import React from "react";
import Gallery from '../../Gallery';

import Nearby from './nearby.png';
import PropertyList from './propertylist.png';
import Refer from './refer.png';

const galleryImages = [
    {
        uri: Nearby,
        caption: 'Property listing, displaying nearby schools and facilities in a table and on a map'
    },
    {
        uri: PropertyList,
        caption: 'A list of properties available through one of the customer estate agents'
    },
    {
        uri: Refer,
        caption: 'Early prototype of agent finder for rebuilt product'
    }
];

export default function PropertyLogic() {
    return (<div>
        <h1>Property Logic (2012)</h1>
        <p>Following my work at Webforma Studios, I moved to Property Logic, a company specialising in websites and other services for estate agents across the country and abroad. Property Logic offers a multi-site platform on which vendors can upload their properties - directly or through a third-party - for viewing by the public and additional services such as print materials.</p>
        <p>I was employed as a web developer at Property Logic, initially due to my interest in resolving some of their performance issues, but also to resolve issues identified via customers through support, and new feature work across the product suite. Within one week of my joining the company, property search query time had improved by 95%, with additional performance improvements added over time.</p>
        <p>Working at Property Logic allowed me to work with a number of different technologies, and gave me a significantly deeper insight into SQL - Particularly the MySQL servers primarily used by the company. With this knowledge I was able to diagnose slow-running queries and provide alternative implementations. My database knowledge came to be well-regarded within the company in the time I was working for them.</p>
        <p>During my time at the company, I was able to resolve several key issues in the platform, liaising with third-party vendors as required to report and resolve issues such as service integration and data quality problems. </p>
        <p>At Property Logic I also learned to find a balance between implementing the required solution and impressing with more. Some examples included taking the initiative with a newly-hired colour blind employee to recolour an area of the system that I was working on, and bringing a report's generation time down from 20 minutes to approximately 15 seconds (Admittedly, partly because I was convinced that I must have broken it to start with; I hadn't). These additional flourishes had to be carefully balanced against the expected time for the task to avoid unnecessary penalty to the business, but I choose to believe that I learned to find a balance between time and delivery value here.</p>
        <p>A highlight of my time at Property Logic was a proof-of-concept feed engine for receiving properties from multiple vendors, replacing the previous solution with its multiple performance implications. The proof-of-concept engine used a daemon written in PHP which launched and monitored child feed processes, with additional plug-in processes (writeable in any language) able to be added over time. An additional monitor process allowed real-time analysis of the platform progress and health and provided a REST-like API for developing additional tools. Sadly, to my knowledge this was never implemented in a production environment, but allowed for a unified, monitorable implementation of a core business feature. Whilst I would have made some different design decisions today, the solution worked and appeared to scale.</p>
        <p>Other work at Property Logic included integration between multiple data vendors to provide a map of local facilities, such as schools and healthcare. This system was designed to be easily extensible, such that new data sources can be added by the company with relative ease. Since my departure, the ability to see nearby train stations has been added without my assistance, suggesting that my documentation and architectural considerations were sufficient for the task.</p>
        <h2>Key Considerations</h2>
        <ul>
            <li>Work with a wide variety of programming languages, including PHP and ASP.</li>
            <li>Mixture of bespoke platforms and PHP frameworks such as Joomla.</li>
            <li>Heavy inbound and outbound data processing through PHP CLI</li>
            <li>Use of JavaScript frameworks such as JQuery and ember.js to create and maintain solutions.</li>
            <li>Deep dive into MySQL, with complex multi-table joins, unions and geographical queries as required.</li>
            <li>Work within a team on an established platform in a range of roles.</li>
        </ul>
        <Gallery images={galleryImages} />
    </div>);
};