import React from "react";

export default function Ircd() {
    return (<div>
        <h1>Ezri - IRC Services Daemon (C Programming)</h1>
        <p>Ezri is a long-term project written in C with an aim to build a fast, efficient but fully-featured IRC "Services" client. An IRC services client connects to an IRC server through a specialised server-to-server connection and reacts to events on the network, allowing features such as nickname and channel registration. Work on Ezri ceased in 2011 due to the continuing demise of IRC, but this work hopefully serves as an interesting conversation piece due to its relative niche audience and the use of C for a relatively complex software engineering effort. </p>
        <p>Ezri is cross-platform, supporting at least Windows and Linux. Other operating systems may also work. The build time is minimal due to a lack of external dependencies, and can run inside 3MB of RAM under most operating systems.</p>
        <h2>Key Considerations</h2>
        <ul>
            <li>Modular extensions for services and commands, allowing new commands to be added, removed or updated without restarting the server.</li>
            <li>NickServ features such as nickname registration and "ghost" protection - Stopping another user from using the same nickname.</li>
            <li>Full HostServ features, including fake hostname groups and group managers, plus access lists.</li>
            <li>APIs for working with most kinds of database. Potential to use either flat files or a database driver such as MySQL.</li>
            <li>Multiple spoken language support through a simple templating system.</li>
            <li>Block-based configuration file allows logical grouping of settings.</li>
            <li>Ability to connect to multiple IRCds. UnrealIRCd communicator included.</li>
        </ul>
        <p>Notable features of Ezri included APIs for database drivers, modular service commands with a live unload/reload system, a block-based configuration file and support for multiple languages.</p>
        <p>Many of the design goals of Ezri are performance-oriented, though others are built as an attempt to replace and supercede a previously-existing version written in the mid 1990s. Unfortunately, I mostly lost interest in further work on the project after roughly two years, and never finished work on the system to the point where I was comfortable using it full-time, but the effort taught me much about C, which was instrumental to some of my later work and taught me more about languages such as PHP, whose official interpreters are written in C.</p>
        <p>Although Ezri is no longer actively developed, it provides an insight into my capabilities with C as of 2010. Ezri is the longest project I have which I can currently share, having been developed for 18 months. A changelog file is included, showing my progress throughout this time - See ezri-changelog.txt</p>
        <p><a href="https://github.com/dominiclamb/Ezri">View Ezri on GitHub (2010)</a></p>
</div>);
};