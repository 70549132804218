import React from "react";

import Gallery from '../../Gallery';

import Search from './search.png';
import NewHomepage from './new_home.png';
import HomepageGridLarge from './homepage_grid_large.png';
import HomepageGridSmall from './homepage_grid_small.png';

const galleryImages = [
    {
        uri: Search,
        caption: 'AJAX search functionality. Note: Contains adult products.',
        nsfw: true
    },
    {
        uri: NewHomepage,
        caption: 'Website homepage, following 2015 redesign.'
    },
    {
        uri: HomepageGridLarge,
        caption: 'Homepage, detailing options available to trade customers. Desktop version.'
    },
    {
        uri: HomepageGridSmall,
        caption: 'Homepage, detailing options available to trade customers. Small screen version.'
    }
];

export default function() {
    return (<div>
        <h1>Bespoke Media Centre</h1>
        <p>As part of my work at <a href="/rocks-off-ltd">Rocks-Off Ltd</a>, I was asked to create a media centre for trade customers to download images and documentation. The solution I came up with received praise by many corporate customers for its appearance and ease of use; this feedback has been relayed back to me on several occasions as a direct result of phone conversations between sales and business customers.</p>
        <p>Customers are presented with a categorised list of media types, ranging from low/hi-res images to conformance documentation. By typing in the appropriate keywords via search, a range of media will be offered for users to download.</p>
        <p>A useful aspect of the system was the "Download All Files" button. With no search term provided, the website would provide an archive of all content. With a search term, the website would dynamically generate an archive for all results matching the search query. This solution avoided custom image "packs" having to be created by the design team and constantly updated when a product was changed or new material was added.</p>
        <p>A separate area of the same system also provided employees of Rocks-Off with a private file repository for bespoke artwork, CAD models and other sensitive materials for the purpose of sharing with customers and partners. These files would remain on the server, sent to a single or small group of users who needed to download the file without a login. The solution to this became known as "fingerprint links" - A key was generated based on a hash of the file contents, and appended to the URL. Employees would request a fingerprint link from the system, and send these out when appropriate. Use of the hash made it highly improbable that customers would be able to guess the existence of a file and download its contents.</p>
        <h2>Key Considerations</h2>
        <ul>
            <li>Users must be able to easily find hi-res and lo-res images for all media on the website.</li>
            <li>A "Download All Files" button must work to download everything in the category, or everything matching the search term. For images, users must be able to select one or more sizes of images.</li>
            <li>Products have a range of names by which they're known, and might be searched with or without punctuation such as hyphens</li>
            <li>A background ZIP operation must be performed for multiple file downloads; users must be made aware of the potential for wait times.</li>
            <li>A separate area to allow storage and retrieval of files for individual customers.</li>
        </ul>
        <p>The result was an AJAX search of product materials, with product search results fading into view. This fade effect is both visually appealing and gives thumbnails a chance to load even on slower connections.</p>
        <p>The AJAX search parameters are passed into the "Download All Files" feature, which performs a zip operation. A progress bar keeps users informed of their estimated queue time.</p>
        <p><strong>Note</strong>: Rocks-Off is a company that sells <em>adult</em> toys, the included images on this page may not be considered work-safe in some environments.</p>
        <Gallery images={galleryImages} />
    </div>);
}