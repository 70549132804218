import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import RouteProvider from "../Routes/RouteProvider";
import { withRouter } from 'react-router';

function renderPrevious(activeRoute) {
    if (activeRoute.previous) {
        return <StyledPrevious to={activeRoute.previous.path}>&laquo; Previous</StyledPrevious>
    }

    return null;
}

function renderNext(activeRoute) {
    if (activeRoute.next) {
        return <StyledNext to={activeRoute.next.path}>Next &raquo;</StyledNext>
    }

    return null;
}

function findActiveRoute(pathname, routes) {
    const routeList = Object.values(routes);
    const activeIndex = routeList.findIndex(route => route.path === pathname);
    if (activeIndex !== -1) {
        const result = {
            title: routeList[activeIndex].name,
            previous: undefined,
            next: undefined
        };

        if (activeIndex > 0) {
            result.previous = routeList[activeIndex - 1];
        }

        if (activeIndex !== routeList.length - 1) {
            result.next = routeList[activeIndex + 1];
        }

        return result;
    }

    return null;
}

function renderTitle(route) {
    if (route) {
        return <span>{route.title}</span>;
    }

    return <span>Dominic Lamb's Portfolio - 2019</span>
}

function renderFooterContent(activeRoute) {
    return <>
    { activeRoute && renderPrevious(activeRoute) }
    { renderTitle(activeRoute) }
    { activeRoute && renderNext(activeRoute) }
    </>
}
function Footer(props) {
    return (<StyledFooter>
        <RouteProvider.Consumer>
            { (routes) => renderFooterContent(findActiveRoute(props.location.pathname, routes)) }
        </RouteProvider.Consumer>
    </StyledFooter>);
}

export default withRouter(Footer);

const StyledFooter = styled.footer`
position: fixed;
display: flex;
align-content: bottom;
bottom: 0;
left: 0;
width: 100%;
background-color: #FFF;
box-shadow: 0px -10px 30px #FFF;
border-top: 1px solid #DDD;
padding: 0.5rem;
`;

const StyledLink = styled(Link)`
color: #000;
font-size: 0.75rem;
text-decoration: none;
user-select: none;
`
const StyledPrevious = styled(StyledLink)`
margin-left: 0.25rem;
margin-right: 1rem;
`

const StyledNext = styled(StyledLink)`
position: absolute;
right: 2rem;
`