import * as React from 'react';

function WorkForce() {
    return <section>
        <h1>WorkForce Software</h1>
        <p>I originally became a part of WorkForce Software via its acquisition of Workplace Systems in June 2016, three months after I had joined the company. At WorkForce I have been able to develop a number of skills that I had not had the opportunity to explore at previous roles, and learn others that I had not previously valued. WorkForce has helped me to understand that the efforts I was making could be divided into more formal disciplines, which has allowed me to separate out and pursue these ideas more thoroughly.</p>
        <p>Work at WorkForce Systems has been focused around its suite of employee scheduling solutions, with a large portion of that time focused around its mobile platform offerings in particular. More recent work within the past 18 months has shifted focus toward a suite of modules built using the ReactJS framework in order to recreate much of the same functionality, but with a user interface refresh and the hope of a more maintainable suite of solutions.</p>
        <p>I started as a software engineer in 2016, and was given the role of Senior Software Engineer at the end of 2017. Since then, I have acted as a champion for the company's software user experience, and have contributed user interface improvements to almost every current product sold by the company. Further to these goals, I have also taught others the techniques involved in modern front end development, with the hope that they continue my efforts with the same level of enthusiasm. I have also set up a number of initiatives to ensure that geographically-distributed employees have explicit opportunities to collaborate and align as we all continue to learn and develop our understanding, ensuring that the product suite remains cohesive and software patterns tend to be similar across products to allow employees to iterate faster. As a result of this, I have received positive feedback from WorkForce employees in multiple countries, occasionally from as high as Executive level.</p>
        <p>During the past year, I have been heavily involved with the company's user interface redesign efforts, working across multiple levels of the business to provide a clean and functional user interface. This work has ranged from collaboration with product owners through to the development of a solution, and has included the training of software engineers unfamiliar with the newer concepts used in the redesign.</p>
        <p>Though my interests have become heavily skewed toward front-end development of late, I have performed a significant amount of work across the entire development stack. Much of this has been integration between the Mobile platform and the main solutions, ensuring that the correct data is available in both systems, though I have been able to find time to create tooling improvements to increase the speed and confidence of software development across all disciplines, with the creation of developer tools and improvements to the company's evolving Continuous Integration pipeline. This work has given me knowledge of the underlying approach to CI/CD, and practical experience with creating build pipelines and Docker containers for varied use cases.</p>
        <p>Outside of regular software development efforts, I have also managed to receive a number of accolades for my work, such as being part of a winning team for an internal company hackathon.</p>
        <h2>Highlights</h2>
        <ul>
            <li>First introduction to a medium-size, distributed agile software development team.</li>
            <li>Worked extensively on company's mobile employee schedule solution.</li>
            <li>Provided front end training materials and guidance to approximately 40 software engineers and user interface designers.</li>
            <li>Internal thought leader for React JavaScript framework as company-wide standard for new user interface work.</li>
            <li>Created internal working groups to solve technological problems.</li>
            <li>Expanded company development toolchain to improve software confidence and reliability.</li>
        </ul>
    </section>
}

export default WorkForce;