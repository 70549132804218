import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import {Route, Switch} from "react-router";
import styled from 'styled-components';
import Footer from './components/Footer';
import Header from "./components/Header";
import routes from "./components/Routes/Routes";
import RouteProvider from "./components/Routes/RouteProvider";
import ScrollToTop from './components/ScrollToTop';

import "typeface-lato";

class App extends Component {
  render() {
    return (
      <RouteProvider.Provider value={routes}>
          <BrowserRouter>
            <ScrollToTop />
            <StyledAppContainer>
              <Header />
              <Switch>
                {
                  Object.values(routes).map(route => <Route exact {...route} key={route.path} />)
                }
              </Switch>
              <Footer />
            </StyledAppContainer>
          </BrowserRouter>
      </RouteProvider.Provider>
      
    );
  }
}

const StyledAppContainer = styled.div`
font-size: 1.2rem;
padding: 0 1rem;
margin-bottom: 3rem;

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: #555;
  :hover {
    color: #000;
    text-decoration: underline;
  }
}

tt {
  cursor: default;
  background-color: #EEE;
  border: 1px solid #AAA;
  padding: 0.25rem;
}`;

export default App;
