import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ArrowRight from './arrow_right.png';
import 'typeface-pt-sans';

export default styled(Link)`
color: #444;
font-family: "PT Sans";
font-size: 1.15rem;
display: block;
padding: 0.5rem;

:before {
    display: inline-block;
    content: "";
    background: url(${ArrowRight});
    border-radius: 100%;
    opacity: 0.5;
    vertical-align: middle;
    text-align: center;
    margin-right: 0.35rem;
    margin-top: -3px;
    width: 16px;
    height: 16px;
}

:hover {
    color: #000;

    :before {
        opacity: 1;
    }
}`;