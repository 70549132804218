import React from "react";
import About from "./About";
import Recent from "./Recent";
import styled from 'styled-components';

const Intro = (props) => {
    return (<div className={props.className}>
        <div className="container">
            <StyledNotice>
                <strong>Note</strong>: [2019-11-21] This portfolio is under active development, and does not include all recent work. New content will be added regularly.
            </StyledNotice>
            <h1>Welcome</h1>
            <p>This website is a portfolio for some of the work I've done in the past few years as a full-stack web and online services developer.</p>
            <p>This is not an exhaustive list of the things I've created, but a showcase of some of the things that best convey my talents. Each page represents a specific project, and its particular highlights.</p>
            <p>I've been developing solutions for customers in a full-time commercial capacity since 2011, and offering freelance web and online service development beforehand since 2008. In that time, I've worked with a wide selection of programming languages, and solved many differing business problems. Please ask for a CV/resume for more information.</p>
            <h2>Technical Details of this Presentation</h2>
            <p>To better represent my current technological interests, this website has been built as a single-page application using the <a href="https://reactjs.org/">React</a> JavaScript framework, held together using React Router. For a larger project, I may have also considered adding TypeScript and Redux into the mix, but I couldn't quite justify it.</p>
            <Recent />
        </div>
        <About />
    </div>);
};

const StyledNotice = styled.div`
background-color: rgba(230, 230, 0, 0.3);
margin-top: 1rem;
padding: 0.5rem;
`;

const StyledIntro = styled(Intro)`
display: grid;
grid-column-gap: 1.5rem;
grid-template-columns: repeat(12, 1fr);

.container {
    grid-column-end: span 8;
}

${About} {
    backgroundL: #F00;
    grid-column-end: span 4;
}
`

export default StyledIntro;