import * as React from 'react';
import styled from 'styled-components';

function ActiveImage(props) {
    return <figure className={props.className}>
        <span onClick={props.close}>Close</span>
        <div>
            <span onClick={props.previous}>&laquo; Previous</span>
            <span onClick={props.next}>Next &raquo;</span>
        </div>
        <img src={props.uri} alt={props.caption} />
        <figcaption>{props.caption}</figcaption>
    </figure>
}

const StyledActiveImage = styled(ActiveImage)`
position: fixed;
display: flex;
flex: 1 1 0;
flex-direction: column;
background-color: rgba(240, 240, 240, 0.97);
box-shadow: 0px 0px 300px #000;
border-radius: 1rem;
align-items: center;
justify-content: center;
top: 7%;
height: 80%;
width: 95%;

div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    user-select: none;
    width: 30%;
    margin: 0.5rem 0;
}
img {
    max-height: 80%;
}

figcaption {
    margin: 2rem 0;
}

> span {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    user-select: none;
}`;

export default StyledActiveImage;