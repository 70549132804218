import React from "react";
import Gallery from '../../Gallery';

import CantonExpress from './cantonexpress.png';
import DDInstallations from './ddinstallations.png';
import PinkMonkey from './pinkmonkey.png';
import ThreeCountiesWater from './threecountieswater.png';
import Versalift from './versalift.png';
import Wrightmans from './wrightmans.png';

const galleryImages = [
    {
        uri: CantonExpress,
        caption: 'Canton Express, a local restaurant, with an online ordering system for customers'
    },
    {
        uri: DDInstallations,
        caption: 'D&D Installations, a family-run property maintenance company'
    },
    {
        uri: PinkMonkey,
        caption: 'The Pink Monkey Company, an arts-and-crafts retailer with a bespoke online store'
    },
    {
        uri: ThreeCountiesWater,
        caption: 'Three Counties Water, who requested a themed area for employees to download resources'
    },
    {
        uri: Versalift,
        caption: 'Versalift, online presence for a company specialising in mounted platform vehicles'
    },
    {
        uri: Wrightmans,
        caption: 'Wrightmans Construction, a Kettering-based construction company'
    },
];

export default function Webforma() {
    return (<div>
        <h1>Webforma Studios (2011-2012)</h1>
        <p>From August 2011 to August 2012, I worked as a web developer at Webforma Studios, a small company based in Kettering specialising in developing bespoke websites for clients as required. Many of the websites we created were for local businesses in the Northamptonshire area, but some were more technically-involved, requiring bespoke functionality.</p>
        <p>My role at Webforma was primarily the creation of the Content Management System (CMS), but quickly extended to full stack development in PHP, JS, and accompanying technologies. A wide range of clients ensured that there were a correspondingly wide variety of sites to develop.</p>
        <p>Websites developed at Webforma included e-commerce systems, local company information pages, take-away food and restaurant sites; and access control systems. The range of technologies made me a stronger developer when I left and allowed me to pursue greater challenges.</p>
        <p>Unfortunately, many of the sites we developed for local businesses have been redeveloped or otherwise no longer exist, screenshots of some of the sites are unavailable. Webforma Studios itself no longer exists.</p>

        <h2>Highlights</h2>
        <ul>
            <li>Built websites for a range of purposes, including trader showcases, e-commerce sites and mortgage lenders.</li>
            <li>Built a membership system for Adrenaline Alley, a UK-based skate park, handling equipment hire and staff control of memberships.</li>
            <li>Worked with a range of payment providers, including SagePay, PayPal and WorldPay.</li>
            <li>Worked with multiple database technologies, such as MySQL, SQL Server and PostgreSQL.</li>
            <li>Strong use of JavaScript libraries and frameworks, especially JQuery.</li>
            <li>Integration of social media features such as Facebook, Twitter, and internal wishlist sharing for e-commerce sites.</li>
        </ul>
        <Gallery images={galleryImages} />
    </div>);
};