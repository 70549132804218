import React from "react";
import { Link } from 'react-router-dom';

export default function RocksOff() {
    return (<div>
        <h1>Rocks-Off Ltd (2013-2016)</h1>
        <p>I joined Rocks-Off Ltd after they reached out to me directly regarding an interview, having received a strong recommendation from a previous employer. Rocks-Off Ltd were seeking a new web developer to maintain and expand their growing web presence for adult-only products, which they were beginning to discover was an expectation for modern businesses.</p>
        <p>At Rocks-Off Ltd, I started as a sole developer building a new <a href="/ecommerce-solution">e-commerce engine</a> to power a redesigned main website, as none of the previous solutions they had used contained (or easily allowed for extensions to introduce) the feature set they desired outside of the core retail experience. The e-commerce engine created for the main site was then re-used and iterated upon to create several profitable websites offering information for business customers and a direct sales opportunity to online customers.</p>
        <p>During my time at Rocks-Off Ltd, I was not only able to create a profitable series of online systems, but was also able to create internal systems for time tracking and <a href="/warehouse-system">warehouse stock management</a>. By the time I left Rocks-Off Ltd in 2013, there were four people directly involved in design and maintenance of these systems, and I was able to scale engineering processes to reduce waste and ensure communication.</p>
        <p>Solutions that I created at Rocks-Off were relied upon across the entire business. By the time I left in 2016, I had created solutions used by warehouse staff, by company directors, sales, and internal tools for the development team.</p>
        <h2>Highlights</h2>
        <ul>
            <li>Built online presence for Rocks-Off.com and several sub-brands.</li>
            <li>Maintained legacy websites and implemented contemporary solutions where reasonable.</li>
            <li>Achieved first-page result for "vibrators" in the UK with zero budget, legitimate SEO techniques.</li>
            <li>Created and managed e-mail campaigns for interested customers.</li>
            <li>Built internal solutions to solve departmental business problems.</li>
            <li>Expanded efforts from a single developer to a small, capable team of specialists.</li>
        </ul>
        <h2>Related Work</h2>
        <ul>
            <li><Link to="/ecommerce-solution">Bespoke E-Commerce Sites</Link>, powering Rocks-Off.com and related brands.</li>
            <li><Link to="/warehouse-system">Bespoke Warehouse System</Link>, integrating with e-commerce system to modernise company warehouse.</li>
            <li><Link to="/media-centre">Media Centre</Link>, allowing access to high quality product assets for business customers.</li>
        </ul>
    </div>);
};