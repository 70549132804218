import React from "react";
import Gallery from '../../Gallery';

import AutoSuggest from './autosuggest.png';
import Stats from './stats.png';
import WarehouseTransaction from './warehouse_transaction.png';

const galleryImages = [
    {
        uri: AutoSuggest,
        caption: 'Autosuggest functionality, capable of searching words in any order, allowing partial matches and optional hyphens.'
    },
    {
        uri: WarehouseTransaction,
        caption: 'Warehouse transaction log, detailing the interpretation of the transaction from raw barcode scanner data.'
    },
    {
        uri: Stats,
        caption: 'Warehouse Statistics System'
    },
];
export default function() {
    return (<div>
        <h1>Bespoke Warehouse System</h1>
        <p>As part of my work at <a href="/rocks-off-ltd">Rocks-Off Ltd</a>, I was asked to create a warehouse management system to handle stock control within the company. The resulting system handles the entire process, from order to dispatch.</p>
        <p>The resulting system interfaces with barcode readers in order to generate transactions, which are capable of performing stock actions. Web interfaces are also provided for manual corrections.</p>
        <p>The system handles the entire picking process, allowing users to create picking sheets and assigning stock, through to sending dispatch notifications to staff.</p>
        <h2>Key Considerations</h2>
        <ul>
            <li>Generate picking list with awareness of warehouse footfall and bay height to generate the most efficient strategy possible.</li>
            <li>Custom search ranking algorithm with loose word matching to find product components.</li>
            <li>Tailored interfaces for desktop, mobile and tablets of various sizes.</li>
            <li>Fine-grained access permissions ensure that users cannot perform stock actions they have no authorisation to perform.</li>
            <li>RESTful data API allows integration with other services.</li>
        </ul>
        <p>Creating this system has allowed for significant positive changes in the way the company has been able to do business. Sales staff are now capable of doing a live check on available stock and inform clients over the phone, rather than taking a provisional order and risk being forced to disappoint the customer at a later point in time.</p>
        <p>Warehouse staff also have accurate accounting for stock throughout the warehouse and can bring up individual stock totals, which has allowed the warehouse system to be extended to determine re-order points for products. This has resulted in fewer products being out of stock, and more product sales as a result.</p>
        <p>An unexpected benefit of the system development was the ability to identify training issues. By tracking which staff most commonly encountered order processing issues, problems could be identified. Previously, there was no way of identifying these kinds of issues.</p>
        <Gallery images={galleryImages}></Gallery>
    </div>);
}