import * as React from 'react';
import styled from 'styled-components';

function showImage(callback, image) {
    return () => callback(image.index);
} 

function Image(props) {
    return <div className={props.className} onClick={showImage(props.onClick, props)}>
            <img src={props.uri} alt={props.caption} />
            <span>{props.caption}</span>
    </div>;
}

const StyledImage = styled(Image)`
text-align: center;
img {
    display: inline-block;
    border: 1px solid rgba(192, 192, 192, 0.8);
    height: 250px;
}
span {
    display: block;
    padding: 0.25rem;
}`

export default StyledImage;