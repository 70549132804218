import React from "react";
import Gallery from '../../Gallery';
import { Link } from 'react-router-dom';
import SchedulingView from './dynamic_scheduling.png';
import TaskingView from './dynamic_scheduling_tasking.png';
import PlanningView from './dynamic_scheduling_planning.png';

const galleryImages = [
    {
        uri: SchedulingView,
        caption: '"Scheduling View", typically used to create shifts for employees.',
    },
    {
        uri: TaskingView,
        caption: '"Tasking View", intended to allow fine-level tweaking of a small number of days\' shifts.'
    },
    {
        uri: PlanningView,
        caption: '"Planning View", allowing longer-term overviews of shift information. Colours represent time of day, stripes represent shifts for employees in external locations.'
    },
];

const DynamicScheduling = (props) => {
    return (<div>
        <h1>Dynamic Scheduling</h1>
        <p>This page summarises my experience with the Dynamic Scheduling project at <Link to="/workforce-software">WorkForce Software</Link>. "Dynamic Scheduling" is a name given to the successor to WorkForce Software's <em>Forecasting & Scheduling</em> product, and is under active development as a replacement for previous solutions. Dynamic Scheduling was launched in October 2019, with a true release anticipated in January 2020.</p>
        <p>Dynamic Scheduling was primarily created to allow retail customers to construct schedules with multiple "tasks" (actions that employees may perform, such as cashiering or shelf stocking) within "shifts" (The duration of an employee's working day, which may comprise one or more tasks and may include breaks), which was a limitation of the previous product. I was brought on to the Dynamic Scheduling project approximately six months after initial prototypes were created, having provided some initial onboarding to the React Framework for the development team previously.</p>
        <p>Since joining the Dynamic Scheduling team I have been involved in key engineering efforts within the product, and have followed development of features from early conceptualising with stakeholders through to delivery of units of work. Some more notable efforts include integrating with the platform-level application framework and creating new APIs for core features. I have also been involved directly in feature development, implementing support for multiple timezones and designing significant portions of the "Shift Panel", the primary editor for altering the primary entities within the application.</p>
        <p>Alongside the engineering work with developing a brand new product, I have also enjoyed watching and helping with the development of team members, some of which had little experience with the frameworks and concepts involved with a JavaScript-based application, but who have been instrumental in creating what truly feels like an innovative solution.</p>
        <h2>Highlights</h2>
        <ul>
            <li>Integrate and evolve with a team building an innovative employee scheduling solution.</li>
            <li>Design and implement APIs to enable teams to create new features.</li>
            <li>Attention to detail for new user interface, with awareness of user journeys and intended use cases.</li>
            <li>Responsive design, with a wide range of supported browsers to account for traditional enterprise hardware.</li>
            <li>Two way communication with wider business to ensure compatibility with brand new, company-wide platform.</li>
        </ul>
        <Gallery images={galleryImages} />
    </div>);
};

export default DynamicScheduling;