import * as React from 'react';
import RouteProvider from '../../../Routes/RouteProvider';
import styled from 'styled-components';
import Link from './Link';

const Recent = () => {
    return (
        <RouteProvider.Consumer>
            {(routes) => (
                <StyledRecent>
                    <div>
                        <h3>Employers</h3>
                        <Link to={routes.workforce.path}>WorkForce Software [Current]</Link>
                        <Link to={routes.rocksoff.path}>Rocks-Off Ltd</Link>
                        <Link to={routes.propertylogic.path}>Property Logic</Link>
                        <Link to={routes.webforma.path}>Webforma Studios</Link>
                    </div>
                    <div>
                        <h3>Recent Work</h3>
                        <Link to={routes.dynamicscheduling.path}>Dynamic Scheduling [2019]</Link>
                        <Link to={routes.ecommerce.path}>E-Commerce Platform [2015]</Link>
                        <Link to={routes.warehouse.path}>Warehouse System [2015]</Link>
                        <Link to={routes.media.path}>Media Centre [2015]</Link>
                    </div>
                    <div>
                        <h3>Freelance / Side Projects</h3>
                        <Link to={routes.calltracker.path}>Call Tracker [2011]</Link>
                        <Link to={routes.ezri.path}>IRC Services Daemon [2010]</Link>
                        <Link to={routes.bbcode.path}>BBCode Parser [2011, 2017]</Link>
                    </div>
                </StyledRecent>
            )}
        </RouteProvider.Consumer>
    );
}

const StyledRecent = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 1rem;

> div {
    border-right: 1px solid #DDD;
    &:last-child {
        border: none;
    }
}

h3 {
    font-size: 1.5rem;
}
a {
    display: block;
    text-decoration: none;   
}
`;
export default Recent;