import * as React from 'react';
import styled from 'styled-components';

function showNsfwImage(callback, image) {
    return () => callback(image.index);
} 
function NsfwImage(props) {
    return <div className={props.className} onClick={showNsfwImage(props.onClick, props)}>
        <div><span>Potentially NSFW image. Click to display thumbnail.</span></div>
        <span>{props.caption}</span>
    </div>;
}
const StyledNsfwImage = styled(NsfwImage)`
text-align: center;
position: relative;

div {
    cursor: pointer;
    background: radial-gradient(circle at 40% 60%, rgba(200, 48, 48, 0.6), rgba(200, 48, 48, 0.6), rgba(0, 0, 0, 0) 60%),
                radial-gradient(circle at 60% 80%, rgba(48, 200, 48, 0.7), rgba(48, 200, 48, 0.7), rgba(0, 0, 0, 0) 50%),
                radial-gradient(circle at 60% 20%, rgba(48, 48, 200, 0.7), rgba(48, 48, 200, 0.7), rgba(0, 0, 0, 0) 40%),
                linear-gradient(to bottom right, #EEE, #F7F7F7);
    border-radius: 20%;
    position: relative;
    display: block;
    margin: auto;
    width: 30%;
    height: 250px;
    span {
        position: absolute;
        top: 40%;
        left: 0%;
        background: rgba(48, 48, 48, 0.7);
        color: #FFF;
        content: "";
        padding: 0.5rem;
        user-select: none;
        width: calc(250px + 0.75rem);
    }

    width: 279px;
}

> span {
    display: block;
    padding: 0.25rem;
}`

export default StyledNsfwImage;