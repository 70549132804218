import React from "react";
import { Link } from "react-router-dom";
import RouteProvider from "../Routes/RouteProvider";
import styled from 'styled-components';

export default function Header() {
    return (
        <StyledHeader>
            <RouteProvider.Consumer>
                { routes => <Link to={routes.home.path}>Dominic Lamb's Portfolio - 2019</Link> }
            </RouteProvider.Consumer>
        </StyledHeader>
    );
}

const StyledHeader = styled.header`
border-bottom: 1px solid #AAA;
font-size: 1.5rem;
margin-left: -1rem;
padding: 0.5rem;

a {
    color: initial;
    text-decoration: none;
}
`