import * as React from 'react';
import styled from 'styled-components';
import Image from './Image';
import ActiveImage from './ActiveImage';
import NsfwImage from './NsfwImage';

function navigateBack(images, currentIndex) {
    let previous;
    if (currentIndex > 0) {
        previous = currentIndex - 1;
    } else {
        previous = images.length - 1;
    }

    return previous;
}

function navigateForward(images, currentIndex) {
    let next;
    if (currentIndex < images.length - 1) {
        next = currentIndex + 1;
    } else {
        next = 0;
    }

    return next;
}

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeImage: null,
            shownNsfw: []
        }

        this.ref = null;
    }

    render() {
        return (<div className={this.props.className}>
            <h2>Gallery</h2>
            <div>
                {
                    this.props.images.map((image, index) => this.renderImage(image, index))
                }
            </div>
            { this.renderActiveImage() }
        </div>
        );
    }

    componentDidMount() {
        document.addEventListener("keydown", this.captureCloseKey, false);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.captureClickOutside, false);
        document.removeEventListener("keydown", this.captureCloseKey, false);
    }

    createRef = (ref) => {
        this.ref = ref;
    }

    isSafeIndex(index) {
        return (!this.props.images[index].nsfw || this.state.shownNsfw.includes(index));
    }

    renderImage(image, index) {
        if (!this.isSafeIndex(index))  {
            return <NsfwImage caption={image.caption} onClick={this.showNsfw} index={index} key={index} />
        }

        return <Image {...image} key={index} onClick={this.openActiveImage} index={index} />
    }

    captureCloseKey = (e) => {
        switch (e.keyCode) {
            case 27:
                this.closeActiveImage();
            break;
            case 39:
                this.navigateNextImage();
            break;
            case 37:
                this.navigatePreviousImage();
            break;
            default:
            break;
        }
    }

    captureClickOutside = (e) => {
        if (this.state.activeImage !== null && this.ref && !this.ref.contains(e.target)) {
            this.closeActiveImage();
        }
    }

    openActiveImage = (index) => {
        this.setState({
            activeImage: index
        });
        document.addEventListener("click", this.captureClickOutside, false);
    }

    closeActiveImage = () => {
        this.setState({
            activeImage: null
        });
        document.removeEventListener("click", this.captureClickOutside, false);
    }

    showNsfw = (index) => {
        this.setState({
            shownNsfw: [
                ...this.state.shownNsfw,
                index
            ]
        });
    }

    navigatePreviousImage = () => {
        if (this.state.activeImage === null) {
            return;
        }

        let previous = navigateBack(this.props.images, this.state.activeImage);

        while (!this.isSafeIndex(previous)) {
            previous = navigateBack(this.props.images, previous);
        }
        this.setState({
            activeImage: previous
        });
    }

    navigateNextImage = () => {
        if (this.state.activeImage === null) {
            return;
        }

        let next = navigateForward(this.props.images, this.state.activeImage);

        while (!this.isSafeIndex(next)) {
            next = navigateForward(this.props.images, next);
        }

        this.setState({
            activeImage: next
        });
    }

    renderActiveImage() {
        if (this.state.activeImage === null) {
            return null;
        }

        const activeImage = this.props.images[this.state.activeImage];

        return <div
            ref={this.createRef}
        >
            <ActiveImage
                close={this.closeActiveImage}
                uri={activeImage.uri}
                caption={activeImage.caption}
                nsfw={activeImage.nsfw}
                previous={this.navigatePreviousImage}
                next={this.navigateNextImage}
            />
        </div>;
    }
}

const getImageCount = (props) => props.images.length > 4 ? 4 : props.images.length;

const StyledGallery = styled(Gallery)`
margin-bottom: 4rem;
> div {
    display: grid;
    cursor: pointer;
    grid-template-columns: repeat(${getImageCount}, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 3rem;
}`

export default StyledGallery;