import Bbcode from '../Pages/Bbcode';
import CallTracker from '../Pages/CallTracker';
import DynamicScheduling from '../Pages/DynamicScheduling';
import Ecommerce from '../Pages/Ecommerce';
import Intro from '../Pages/Intro';
import Ircd from '../Pages/Ircd';
import MediaCentre from '../Pages/MediaCentre';
import RocksOff from '../Pages/RocksOff';
import Warehouse from '../Pages/Warehouse';
import Webforma from '../Pages/Webforma';
import WorkForce from '../Pages/WorkForce';
import PropertyLogic from '../Pages/PropertyLogic';

const routes = {
    home: {
        path: '/',
        component: Intro,
        name: 'Introduction'
    },
    workforce: {
        path: '/workforce-software',
        component: WorkForce,
        name: 'WorkForce Software'
    },
    dynamicscheduling: {
        path: '/dynamic-scheduling',
        component: DynamicScheduling,
        name: 'Dynamic Scheduling'
    },
    rocksoff: {
        path: '/rocks-off-ltd',
        component: RocksOff,
        name: 'Rocks-Off Ltd'
    },
    ecommerce: {
        path: '/ecommerce-solution',
        component: Ecommerce,
        name: 'E-Commerce Solution'
    },
    media: {
        path: '/media-centre',
        component: MediaCentre,
        name: 'Media Centre'
    },
    warehouse: {
        path: '/warehouse-system',
        component: Warehouse,
        name: 'Warehouse System'
    },
    propertylogic: {
        path: '/property-logic',
        component: PropertyLogic,
        name: 'Property Logic'
    },
    webforma: {
        path: '/webforma',
        component: Webforma,
        name: 'Webforma Studios'
    },
    calltracker: {
        path: '/call-tracker',
        component: CallTracker,
        name: 'Call Tracker'
    },
    ezri: {
        path: '/irc-service',
        component: Ircd,
        name: 'IRC Service'
    },
    bbcode: {
        path: '/bbcode-parser',
        component: Bbcode,
        name: 'BBCode Parser'
    }
}

export default routes;