import * as React from "react";
import styled from 'styled-components';

const About = (props) => {
    return (<div className={props.className}>
    <h2>About Me</h2>
    <p>My name is Dominic Lamb, I'm a web developer currently based in Northamptonshire. My work has historically involved a mixture of PHP, JavaScript and SQL in some capacity, though I have had the opportunity to branch out on many occasions across a wide variety of programming languages.</p>
    <p>Outside of work, I enjoy playing and composing music. I also like to travel, having had the fortune to visit many parts of the world. I'm also a keen runner, and have taken part in several distance running events.</p>
    <p>I'm always interested in learning new things, and spend a lot of my spare time reading technology and engineering news, or experimenting with new approaches - I often find that the solution I'm seeking in my programming can be inspired by completely unrelated work performed in another field.</p>
    <p>My interest in programming comes from the joy in solving problems, and a desire to help people communicate. Many projects I've undertaken in the past have emerged from a desire to help people better reach each other online.</p>
    <h2>Contact Me</h2>
    <p>If you would like to discuss my work, please e-mail me at <a href="mailto:lamb.dominic@gmail.com">lamb.dominic@gmail.com</a>. Other contact details such as my phone number are available on request.</p>
    </div>);
}

const StyledAbout = styled(About)``;

export default StyledAbout;