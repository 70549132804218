import React from "react";
import Gallery from '../../Gallery';

import InitialCall from './initial_call.png';

const galleryImages = [
    {
        uri: InitialCall,
        caption: 'Initial call page, used by support staff to log new requests'
    }
];

export default function CallTracker() {
    return (<div>
    <h1>Call Tracker (2011)</h1>
    <p>The call tracker in this portfolio was a freelance project commissioned by a city council in 2011, in order to log calls within their housing department. The system, as used by council staff, logs the details of the call - The address of the house, the issue the tenant is having, and the status of the job, amongst other things - and provides a searchable historical database of entries.</p>
    <p>As of 2015, the system has been rolled out to the entire department, plus two additional callcentres, which is far beyond its additional scope. It is not uncommon for 80 people to use the system at the same time across the three buildings, making it both fast-performing and reliable.</p>
    <p>The system itself has been maintained within the council and further developed upon with minimum involvement from myself, indicating that the code was sufficiently clear and documented that these improvements could be made.</p>
    <h2>Key Considerations</h2>
    <ul>
        <li>Internet Explorer 7 compatibility, but remain forward-thinking for hoped upgrade.</li>
        <li>Smooth performance for all users despite significant database query traffic for searches.</li>
        <li>Embedded form tools such as address autosuggest tool, itself capable of finding partial matches in the council's address database as people type</li>
        <li>Export tool for stored data via Excel-compatible formats to allow for reporting.</li>
        <li>Simple enough implementation required to diagnose issues without physical or remote access to the machines.</li>
        <li>The potential for system modifications by company staff had to be considered amongst the use cases.</li>
    </ul>
    <p>As of 2015, the system has run without downtime for almost four years. Reportedly, it's even considered to be the most reliable system they have, persisting even when other aspects of their IT infrastructure have issues.</p>
    <p>The council in question has since updated their browsers to Internet Explorer 9, no issues were reported from the upgrade. I believe that some or all of this aspect of the council was spun off to at least one separate company, which also ended up using the system.</p>
    <Gallery images={galleryImages} />
</div>);
};