import * as React from 'react';
import Gallery from '../../Gallery';

import AdminBar from './admin_bar.png';
import AdminBarMobile from './admin_bar_mobile.png';
import Coupons from './coupons.png';
import ProductDetailsWayback from './product_details_wayback.png';
import ProductList from './products_old.png';

const galleryImages = [
    {
        uri: AdminBar,
        caption: 'Administrator Dashboard, Desktop'
    },
    {
        uri: AdminBarMobile,
        caption: 'Administrator Dashboard, Mobile'
    },
    {
        uri: ProductList,
        caption: 'List of products, initial launch design',
        nsfw: true
    },
    {
        uri: Coupons,
        caption: 'Coupon Generator'
    },
    {
        uri: ProductDetailsWayback,
        caption: 'Redesigned product details page, via Wayback Machine',
        nsfw: true
    }
];

export default function() {
    return (<div>
        <h1>Bespoke E-Commerce Website</h1>
        <p>This website, developed during my time at <a href="/rocks-off-ltd">Rocks-Off Ltd</a>, was an evolving e-commerce system used on the main website of rocks-off.com. Originally intended as a separate website in order to launch a brand new product, it became the basis of the main Rocks-Off.com website in 2013, and was refreshed most significantly for the Black Friday 2015 launch sale. As images were taken during development and not updated post-launch, some information has been redacted, in addition to the "news updates" section.</p>
        <p>The primary goal of the e-commerce system is to allow users to comfortably make product purchases, maintaining confidence throughout the user journey; this is especially important as many users will not be comfortable with discussing their purchase given the nature of the product, and will therefore abandon the purchase rather than requesting support. As a means to achieve this, secondary goals include allowing users to view detailed product descriptions, an SEO-friendly markup and page design, and the ability to contact the company in the event of a question, problem or business sales enquiry. The screenshots of this site show pages of the administration system on mobile and desktop browsers, showing the responsive design of the page elements. This allows staff to view and manage orders and enquiries from anywhere, allowing for responses outside of regular business hours and easy information lookup for mobile employees.</p>
        <h2>Key Considerations</h2>
        <ul>
            <li>The website must provide the user with the confidence to place a purchase. Customers must feel as though they've made an informed choice from a high-quality retailer.</li>
            <li>Administrators to the site must be able to easily view all content from a range of conceivable devices, and must be able to quickly receive an overview of the information they consider important.</li>
            <li>The website must function properly in a wide range of browsers. Features should not be closed off to users due to their choice of browser or device.</li>
            <li>It should be as easy as possible to purchase the product. Allow guest checkout, location finder, and automatic form completion wherever possible.</li>
            <li>Data APIs where appropriate for internal use and business customers, aiding third-parties and extending front-end capabilities.</li>
        </ul>
        <p>Later iterations of the website e-commerce system furthered the use of modules for features, which allowed for improvements to be made to individual components which could then be fed back into existing sites, and enabling multiple websites to benefit from new developments.</p>
        <p>This work was used for the initial launch of the redesigned Rocks-Off.com website in 2013, supplying individuals and businesses with adult toys. The website was later redesigne again for Black Friday 2015, the website was used to market products to consumers and businesses alike. Customers have been buying from rocks-off.com in confidence, and did so regularly throughout the time that I was employed there.</p>
        <Gallery images={galleryImages}></Gallery>
    </div>);
}